<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id!=null">Edit {{title}}</b>
      <b v-else>Create {{title}}</b>
    </span>
    <!-- <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <payment-method :id="data.paymentMethodId" @data="setPaymentMethodId"></payment-method>
        <span
          class="text-danger text-sm"
          v-show="errors.has('PaymentMethod')"
        >{{ errors.first('PaymentMethod') }}</span>
      </div>
    </div> -->
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Name"
          v-validate="'required'"
          name="Name"
          v-model="data.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{ errors.first('Name') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Due Invoice Period (days)"
          v-validate="'required|numeric|min_value:1'"
          name="DueInvoicePeriod"
          v-model="data.due_invoice_period"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('DueInvoicePeriod')"
        >{{ errors.first('DueInvoicePeriod') }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose">Close</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
//import PaymentMethod from "./PaymentMethod";
export default {
  components: {
    //PaymentMethod
  },
  props: {
    id: {
      type: Number
    },
    url: {
      type: String
    },
    title: {
      type: String
    }
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          //paymentMethodId: null,
          name: "",
          due_invoice_period: ""
        }
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        // if (!this.data.paymentMethodId) {
        //   this.errors.add({
        //     field: "PaymentMethod",
        //     msg: "The Payment Method field is required"
        //   });
        //   result = false;
        // }

        if (result) {
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      return {
        //payment_method_id: this.data.paymentMethodId,
        name: this.data.name,
        due_invoice_period: parseInt(this.data.due_invoice_period)
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.id = resp.data.id;
            //this.data.paymentMethodId = resp.data.payment_method_id;
            this.data.name = resp.data.name;
            this.data.due_invoice_period = resp.data.due_invoice_period;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    // setPaymentMethodId(val) {
    //   console.log(val, 'set')
    //   this.data.paymentMethodId = val;
    // }
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    }
  }
};
</script>
<style >
</style>
